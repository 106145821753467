<template>
        <loading :active="isLoading" 
        :can-cancel="true" 
        :on-cancel="onCancel"
		color="#ffc107"
        :is-full-page="fullPage"></loading><Toast />
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<!-- <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" /> -->
							<!-- <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" /> -->
						</div>
					</template>

					 <template v-slot:end>
						<!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" /> -->
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template> 
				</Toolbar>
				
				<DataTable :scrollable="true" scrollHeight="800px"  scrollDirection="both" :loading="loading" ref="dt" :lazy="true" :totalRecords="totalRecords" :paginator="true" :value="products" v-model:selection="selectedProducts" :dataKey="columns[0]"  :rows="limit" :filters="filters"
							@page="onPage($event)"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,totalRecords]"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" responsiveLayout="scroll" class="mt-3" style="font-size: 12px"
          :rowHover="true" showGridlines>
					<template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0"><b>Manage User Queries</b> </h5>
							<Button icon="pi pi-refresh" @click="get_list()" />
							<!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
						</div>
					</template>
					<Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>
					<!-- <Column  selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					    <Column :frozen="true" :style="{width:'10px'}" v-if="columns.length>0" :field="columns[0]" :header="'Id'" ></Column>
						<Column :frozen="true" :style="{width:'100px'}"  v-if="columns.length>0" :field="columns[1]" :header="'User Id'" ></Column>
						<Column :frozen="true" :style="{width:'100px'}"  v-if="columns.length>0" :field="columns[2]" :header="'User Name'" ></Column>
						<!-- <Column :style="{width:'150px'}" v-if="columns.length>0" :field="columns[3]" :header="columns[3].toUpperCase()" ></Column> -->
						<Column :style="{width:'150px'}" v-if="columns.length>0" :field="columns[4]" header="QUERY TYPE" ></Column>
						<!-- <Column :style="{width:'150px'}"  v-if="columns.length>0" :field="columns[5]" :header="columns[5].toUpperCase()" ></Column> -->
						<Column :style="{width:'150px'}" v-if="columns.length>0" :field="columns[6]" header="SUB QUERY TYPE" ></Column>
						
						<Column :style="{width:'150px'}" v-if="columns.length>0"  :header="'File Path'" >
						<template #body="{data}">
                            <a v-if="data.filE_PATH" :href="data.filE_PATH" target="_blank">View</a>
                        </template>
						</Column>
						<Column :style="{width:'150px'}" v-if="columns.length>0" :field="columns[8]" :header="columns[8].toUpperCase()" ></Column>
						<Column :style="{width:'150px'}" v-if="columns.length>0" :field="columns[9]" :header="columns[9].toUpperCase()" ></Column>
						<Column :style="{width:'150px'}" v-if="columns.length>0" :field="columns[10]" :header="columns[10].toUpperCase()" ></Column>
						<Column :style="{width:'150px'}" v-if="columns.length>0" :field="columns[11]" :header="columns[11].toUpperCase()" ></Column>
						<Column :style="{width:'150px'}" v-if="columns.length>0"  header="Assign to" >
							<template #body="{data}">
                            <p v-if="data.teacheR_ID">{{data.teacheR_NAME[0]}}</p>	
                        </template>
						</Column>
						
						<!-- <Column  v-if="columns.length>0" :field="columns[7]" :header="columns[7].toUpperCase()" ></Column> -->
					<Column headerStyle="min-width:15rem;" frozen alignFrozen="right">
						<template #body="slotProps">
							<Button v-tooltip="'reply'" icon="pi pi-reply" class="p-button-rounded p-button-success mr-2"  @click="replyUserQuery(slotProps.data)" />
							
							<Button icon="pi pi-eye" class="p-button-rounded p-button-warning mr-2" v-tooltip="'view comments'" @click="viewUserQuery(slotProps.data)" />
							
							<Button v-if="show" icon="pi pi-forward" class="p-button-rounded p-button-secondary mr-2" v-tooltip="'Assign'" @click="assignUserQuery(slotProps.data)" />
							<!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteProduct(slotProps.data)" /> -->
						</template>
					</Column>
				</DataTable>



				<Dialog v-model:visible="productDialog" :style="{width: '800px'}" header="User Query Details" :modal="true" class="p-fluid">
					<DataTable :scrollable="true" scrollHeight="400px"  scrollDirection="both" :loading="loading" ref="dt"  :totalRecords="totalcommentRecords" :paginator="true" :value="user_comments"  :dataKey="id"  :rows="limitcomment" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,20,]"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" responsiveLayout="scroll" class="mt-3">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">User Comments</h5>
							<!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
						</div>
					</template>
					
				        <Column header="id"  style="min-width:3rem">
						<template #body="{data}">
                            {{data.id}}
                        </template>
                    </Column>
					<Column header="Comment" style="width:12rem">
                        <template #body="{data}">
                            {{data.comments}}
                        </template>
                    </Column>    
                    
					    <Column header="File Path" style="width:12rem">
                        <template #body="{data}">
                            <a v-if="data.filE_PATH" :href="data.filE_PATH" target="_blank">View</a>
                        </template>
                    </Column>
					    <Column header="Date" style="width:12rem">
                        <template #body="{data}">
                            {{data.createD_AT}}
                        </template>
                    </Column>
					
					    <Column header="Created By" style="width:12rem">
                        <template #body="{data}">
                            {{data.created_by}}
                        </template>
                    </Column>
                    
				</DataTable>

					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
					</template>
				</Dialog>
				<Dialog v-model:visible="queryreplyDialog" :style="{width: '800px'}" header="User Query Reply" :modal="true" class="p-fluid">
					<loading :active="isLoadingModel" 
					color="#ffc107"
					:is-full-page="fullPageModel"></loading>
					
					<div class="field">
						<label for="description">Comment</label>
						<Textarea id="description"   v-model="comment"  required="true" rows="3" cols="20" :class="{'p-invalid': submitted && comment_error}" />
						<small class="p-invalid" v-if="submitted && comment_error">Comment required.</small>
					</div>
					<div class="field">
						<label for="file">File</label>
						<FileUpload mode="basic" id="file_attachment"
                                ref="file_attachment"
                                 @change="handleFileUpload" name="demo[]" accept="application/pdf,image/jpeg,image/png" :maxFileSize="1000000" />

					</div>

					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog1"/>
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="replyUserQuerySave" />
					</template>
				</Dialog>
				<Dialog v-model:visible="queryassignDialog" :style="{width: '600px'}" header="User Query Assign" :modal="true" class="p-fluid">
					<loading :active="isLoadingModel" 
					color="#ffc107"
					:is-full-page="fullPageModel"></loading>
					
					<div class="col-12">
					<label for="">Type</label>
					<Dropdown @change="reset_dropdown()" v-model="type_id" :options="type" optionLabel="name" placeholder="Select" />
					</div>
					
					<div class="col-12" v-if="type_id.value=='Network'">
						<label for="">Query Assign To Network</label>
					<Dropdown v-model="network_id" :options="NetworkList" optionLabel="name" placeholder="Select" :class="{'p-invalid': submitted && network_error}" />
					</div>
					<!-- {{TeacherList}} -->
					
					<div class="col-12" v-if="type_id.value=='Teacher'">
						<label for="">Query Assign To Teacher  </label>
					<Dropdown v-model="teacher_id" :options="TeacherList" optionLabel="name" placeholder="Select" :class="{'p-invalid': submitted && teacher_error}" />
						<small class="p-invalid" v-if="submitted && teacher_error">Teacher required.</small>
					</div>
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog2"/>
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveQueryAssign" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<loading :active="isLoadingModel" 
					color="#ffc107"
					:is-full-page="fullPageModel"></loading>
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete <b>{{product.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteProduct" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<loading :active="isLoadingModel" 
					color="#ffc107"
					:is-full-page="fullPageModel"></loading>
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete the selected products?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import { createApp } from "vue";

import {FilterMatchMode} from 'primevue/api';
import ProductService from '../service/ProductService';
import axios from 'axios'
import VueSimpleAlert from "vue-simple-alert";


//import * as Survey from "survey-vue";
//import Vuetify from 'vuetify'

// import axios from "axios";
import apis from "@/apis";
const app = createApp({})
// app.use(VueSimpleAlert);

export default {
	data() {
		return {
			page_no:1,
			show:false,
			U_TYPE:"",
			products: null,
			fullPage:true,
			
			fullPageModel:false,
			productDialog: false,
			queryreplyDialog: false,
			totalcommentRecords:0,
			queryassignDialog: false,
			type:[ { name: "Network", value: "Network" },{ name: "Teacher", value: "Teacher" }],
			TeacherList:[],
			NetworkList:[],
			teacher_id:'',
			type_id:'',
			network_id:'',
			deleteProductDialog: false,
			deleteProductsDialog: false,
			lazyParams:{},
			comment_error:false,
			teacher_error:false,
			network_error:false,
			product: {},
			selectedProducts: null,
			filters: {},
			offset:1,
			isLoading:false,
			isLoadingModel:false,
			loading: false,
			limit:10,
			limitcomment:5,
			columns:[],
			user_comments:[],
			userquerydata:{
				ID:0,
				USER_ID:"",
				USER_NAME:"",
				QUERY_TYPE:"",
				QUERY_TYPE_NAME:"",
				SUBQUERY_TYPE:"",
				SUBQUERY_TYPE_NAME:"",
				FILE_PATH:"",
				QUERY:"",
				CREATED_AT:"",
				CREATED_BY:"",
				STATUS:"",
			},
			
			pageno:1,
			file_attachment:'',
			comment:"",
	
			submitted: false,
			totalRecords:0,
			id:'',
			statuses: [
				{label: 'INSTOCK', value: 'instock'},
				{label: 'LOWSTOCK', value: 'lowstock'},
				{label: 'OUTOFSTOCK', value: 'outofstock'}
			]
		}
	},
	productService: null,
	async created() {
		this.U_TYPE=localStorage.getItem("user_key");
		if(this.U_TYPE=='teacher' || this.U_TYPE=='assteacher' || this.U_TYPE=='network')
		{this.show=false;

		}else{
this.show=true;
		}
		
		this.productService = new ProductService();
		this.initFilters();
		
	},
	async mounted() {
		// this.productService.getProducts().then(data => this.products = data);
				this.id= this.$route.params.id;
		
		 this.lazyParams = {
            first: 0,
            rows: this.limit,
            sortField: null,
            sortOrder: null,
            filters: this.filters
        };
		if(this.totalRecords==0)
		{
			await this.get_count();
		}
		await this.get_list();
		this.teacherlistdropdown();
		this.networklistdropdown();
		//alert(this.id);
	},
	watch:{
		    async $route() {
				this.products=[];
     this.columns=[];
	 this.totalRecords=0;
	 this.id= this.$route.params.id; 
	
		await this.get_count();
		this.get_list();
	 
    }

	},
	methods: {
		
		reset_dropdown(){
			this.teacher_id = { name: "", value: "" };
			this.network_id = { name: "", value: "" };
		},
		get_class()
		{
			if(this.comment_error==true)
			{
				return "p-invalid";
			}
		},
		handleFileUpload() {
      		this.file_attachment = this.$refs.file_attachment.files[0];
			//alert(this.$refs.file_attachment.files[0].name);
		},
		async onPage(event) {
            this.lazyParams = event;
			this.page_no=event.page+1;
			this.limit=event.rows;
			// console.log(event);
			await this.get_count();
			if(this.totalRecords>0)
			{
            	this.get_list();
			}
        },
		
		 get_count: function() {
			var data = {		
    		"count":true
			};
			this.loading=true;
	  var promise = apis.list(data,9);
      promise
        .then(response => {
			
			this.loading=false;
			console.log(response);
			this.totalRecords=response.data;
			//alert(this.totalRecords);
		});
		
			console.log(data);
			
		},
		get_list: function() {
			var type="";
			if(this.U_TYPE=='teacher' || this.U_TYPE=='assteacher')
			{	type='teacher';

			}else if(this.U_TYPE=='network')
			{
				type='network';
			}

			var data = {		
    		"Limit":this.limit,
    		"page_no":this.page_no,
    		"count":false,
			"id":localStorage.getItem("id"),
			"type":type
			};
			this.isLoading=true;
			this.loading=true;
	  var promise = apis.list(data,9);
      promise
        .then(response => {
			this.isLoading=false;
			this.loading=false;
			console.log(response);
			this.products=response.data;
			console.log(this.products[0]);
			if(this.columns.length==0)
			{
				if(this.products.length>0)
				{
					this.columns= Object.keys(this.products[0]);
				}
			}
		});
		
			console.log(data);
			
		},
		networklistdropdown: function(){

			var data = {		
    		
			};
			this.isLoading=true;
			this.loading=true;
	  var promise = apis.helpdesknetworkusersdropdown();
      promise
        .then(response => {
			this.isLoading=false;
			this.loading=false;
			this.NetworkList=response.data;
			
		});

		},
		teacherlistdropdown: function() {
			var data = {		
    		"Limit":100,
    		"page_no":1,
    		"count":false
			};
			this.isLoading=true;
			this.loading=true;
	  var promise = apis.teacherlistdropdown();
      promise
        .then(response => {
			this.isLoading=false;
			this.loading=false;
			this.TeacherList=response.data;
			
		});
		
			console.log(data);
			
		},
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return;
		},
		openNew() {
			this.product = {};
			this.submitted = false;
			this.productDialog = true;
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		hideDialog1() {
			this.queryreplyDialog = false;
			this.submitted = false;
		},
		hideDialog2() {
			this.queryassignDialog = false;
			this.submitted = false;
		},
		saveProduct() {
			this.submitted = true;
			if (this.product.name.trim()) {
			if (this.product.id) {
				this.product.inventoryStatus = this.product.inventoryStatus.value ? this.product.inventoryStatus.value: this.product.inventoryStatus;
				this.products[this.findIndexById(this.product.id)] = this.product;
				this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
				}
				else {
					this.product.id = this.createId();
					this.product.code = this.createId();
					this.product.image = 'product-placeholder.svg';
					this.product.inventoryStatus = this.product.inventoryStatus ? this.product.inventoryStatus.value : 'INSTOCK';
					this.products.push(this.product);
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
				}
				this.productDialog = false;
				this.product = {};
			}
		},
		editProduct(product) {
			this.product = {...product};
			this.productDialog = true;
		},
		async viewUserQuery(product) {
			this.product = {...product};
			//alert(this.product.id);
			this.userquerydata={
				ID:this.product.id,
				USER_ID:this.product.useR_ID,
				USER_NAME:this.product.useR_NAME,
				QUERY_TYPE:this.product.querY_TYPE,
				QUERY_TYPE_NAME:this.product.querY_TYPE_NAME,
				SUBQUERY_TYPE:this.product.subquerY_TYPE,
				SUBQUERY_TYPE_NAME:this.product.subquerY_TYPE_NAME,
				FILE_PATH:this.product.filE_PATH,
				QUERY:this.product.query,
				CREATED_AT:this.product.createD_AT,
				CREATED_BY:this.product.createD_BY,
				STATUS:this.product.status
			};
			var data = {		
				"QUERY_ID":this.product.id
				};
				this.loading=true;
				var promise = apis.getUserQueriesComments(data);
			 await	promise
			.then(response => {
				
				this.loading=false;
				console.log(response);
				this.user_comments=response.data;
				this.totalcommentRecords=response.data.length;
				//alert(this.totalRecords);
			});
			this.productDialog = true;
		},
		replyUserQuery(product) {
			this.comment='';
			this.file_attachment='';
			this.product = {...product};
			this.queryreplyDialog = true;
		},
		saveQueryAssign()
		{ var data="";
		this.submitted=true;
			//------------------------------------------------------------
			if(this.type_id.value=='Teacher')
			{
				this.network_id = { name: "", value: "" };
				if(this.teacher_id.code==undefined)
				{
				this.teacher_error=true;
				return false;
				}
				else
				{
				this.teacher_error=false;
				}
				 data = {		
    						"Id":this.product.id,
							"TEACHER_ID":this.teacher_id.code,
							"NETWORK_USER_ID":null,
							"UPDATED_BY":"1234"
						};
			}
			//-----------------------------------------------------------------
			else if(this.type_id.value=='Network')
			{
				this.teacher_id = { name: "", value: "" };
				if(this.network_id.code==undefined)
				{
				this.network_error=true;
				return false;
				}
				else
				{
					this.network_error=false;
				}
				 data = {		
    						"Id":this.product.id,
							"NETWORK_USER_ID":this.network_id.code,
							"TEACHER_ID":null,
							"UPDATED_BY":"1234"
						};
			}
			//-------------------------------------------------------------------
			else{
				 this.$toast.add({
          							severity: "error",
          							summary: "error",
          							detail: "Please Select Type",
          							life: 3000,
        		});
        return false;

			}
			
			console.log(data);
			
			// alert(this.teacher_id.code)
			
			this.isLoadingModel=true;
			var promise = apis.assignUserQuery(data);
			promise
				.then(responseapi => {
					if(responseapi.status==200)
					{
						this.queryassignDialog=false;
						this.$swal.fire(responseapi.data);
						this.get_list();
						this.isLoadingModel=false;
						this.teacher_id='';
					}
			});

		},	
		replyUserQuerySave() {
			//this.product = {...product};
			 var d = new Date();
        	var file_name3="";
			if (this.file_attachment.type == undefined) {
          file_name3 = null;
        } else {
       			file_name3 =
                  d.getTime() +
                  "_" +
                  d.getMilliseconds() +
                  "_" +
                  this.file_attachment.name;
        }

			
			var data = {		
    		"QUERY_ID":this.product.id,
			"COMMENTS":this.comment,
			"FILE_PATH":file_name3,
			"CREATED_BY":localStorage.getItem("id")
			};
			this.submitted=true;
			console.log(data);
			if(this.comment=="")
			{
				this.comment_error=true;
				return false;	
			}
			else
			{
				this.comment_error=false;
			}
			// return;
			//this.loading=true;
			this.isLoadingModel=true;
			var promise = apis.saveUserComment(data);
			promise
				.then(responseapi => {
					if(responseapi.status==200)
					{
						if(this.file_attachment.name)
						{
				// 			 axios
                //   .post(
                //     "https://7gdt5szac1.execute-api.ap-south-1.amazonaws.com/Prod/api/Fileupload/geturl",

                //   {
                //     "filePath": "userquery/"+this.file_attachment.name,
                //     "contentType": this.file_attachment.type
                //   })

				var file_data={
                  filePath:"userquery/" + file_name3,
                  contentType: this.file_attachment.type

                }
				var promise5 = apis.uploadFile(file_data);
                promise5
                  .then(response => {
                    axios
                    .put(
                        response.data.url,
                      this.file_attachment,
                      {
                        headers: {
                          
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachment.type
                        }
                      }
                    )
                    .then(response => {
					      this.$swal(responseapi.data);


                      this.queryreplyDialog = false;
	
							this.isLoadingModel=false;
                    }).catch(error => {
						this.isLoadingModel=false;
                     // $("#exampleModal").modal("hide");
                      //this.text = response.data.message;
                      //$("#error_modal").modal("show"); 
					 	this.$swal("error to upload file");

					  //alert();
                    });
                  });
						}
						else
						{
							this.$swal(responseapi.data);
							// this.hideDialog1();
							this.queryreplyDialog = false;
	
							this.isLoadingModel=false;
						
						}
					}
					else
					{
						this.$swal(responseapi.data);
						// this.hideDialog1();
						this.queryreplyDialog = false;
	
						this.isLoadingModel=false;
					}
					//alert(this.totalRecords);
				}).catch(error => {
                     //console.log(error);
					 this.isLoadingModel=false;
					 this.queryreplyDialog = false;
	

					 this.$swal.fire(error.response.data);
                    });
				
		},
		assignUserQuery(product) {
			this.product = {...product};
			this.type_id = { name: "", value: "" };
			this.teacher_id = { name: "", value: "" };
			this.network_id = { name: "", value: "" };
			this.queryassignDialog = true;
		},
		confirmDeleteProduct(product) {
			this.product = product;
			this.deleteProductDialog = true;
		},
		deleteProduct() {
			this.products = this.products.filter(val => val.id !== this.product.id);
			this.deleteProductDialog = false;
			this.product = {};
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteProductsDialog = true;
		},
		deleteSelectedProducts() {
			this.products = this.products.filter(val => !this.selectedProducts.includes(val));
			this.deleteProductsDialog = false;
			this.selectedProducts = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	},
//	components:{Survey}
		
}
</script>
<style scoped>
      .swal2-container {
  z-index: 10000!important;
}

</style>
<style scoped lang="scss">

@import '../assets/demo/badges.scss';
</style>
